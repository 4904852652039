import { PureComponent } from 'react';

export class TableListIcon extends PureComponent {
  render() {
    return (
      <svg viewBox="0 0 512 512" {...this.props}>
        <path
          fill="currentColor"
          d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm64 0l0 64 64 0 0-64L64 96zm384 0L192 96l0 64 256 0 0-64zM64 224l0 64 64 0 0-64-64 0zm384 0l-256 0 0 64 256 0 0-64zM64 352l0 64 64 0 0-64-64 0zm384 0l-256 0 0 64 256 0 0-64z"
        />
      </svg>
    );
  }
}
